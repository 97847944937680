import { locationTrackingHelper } from "@src/app/locationTracking/locationTracking.helper";
import { RadarErrors } from "@src/app/locationTracking/locationTracking.types";
import { deprecatedDoNotUseLogError, logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { useQuery } from "@tanstack/react-query";
import { RadarGeofence, RadarTrackCallback } from "capacitor-radar";
import { isEmpty } from "lodash";

const RADAR_LOCATION_CACHE_TIMEOUT_MILLISEC = 5000;
const RADAR_HANDLED_ERRORS: string[] = [RadarErrors.ERROR_NETWORK, RadarErrors.ERROR_PERMISSIONS];

function isAgentInsideGeofence(geofences: RadarGeofence[] = [], facilityId: string) {
  return geofences.some(({ externalId }) => externalId === facilityId);
}

function logGeofenceError(error: Error, shiftId: string) {
  if (!RADAR_HANDLED_ERRORS.includes(error.message)) {
    deprecatedDoNotUseLogError({
      message: `Error while requesting Radar API trackOnce. Shift ID ${shiftId}`,
      metadata: { error },
    });
  }
}

interface LogGeofenceSuccessProps {
  facilityId: string;
  shiftId: string;
  data: RadarTrackCallback;
}

function logGeofenceSuccess({ facilityId, shiftId, data }: LogGeofenceSuccessProps) {
  const isInsideFacilityGeofence = isAgentInsideGeofence(data?.user?.geofences, facilityId);
  logEvent(USER_EVENTS.RADAR_API, {
    shiftId,
    facilityId,
    userId: data?.user?.userId,
    latitude: data?.location?.latitude,
    longitude: data?.location?.longitude,
    accuracy: data?.location?.accuracy,
    fraud: data?.user?.fraud,
    status: data?.status,
    deviceId: data?.user?.deviceId,
    isInsideFacilityGeofence,
  });
}

function useRadarLocationApi(facilityId: string, shiftId: string) {
  const radarLocationResponse = useQuery<RadarTrackCallback, Error>({
    queryKey: ["third-party-radar-location-api", facilityId],
    queryFn: async () => locationTrackingHelper.handleTrackOnce(),
    onError: (error) => {
      logGeofenceError(error, shiftId);
    },
    onSuccess: (data) => logGeofenceSuccess({ facilityId, shiftId, data }),
    staleTime: RADAR_LOCATION_CACHE_TIMEOUT_MILLISEC,
    enabled: !isEmpty(facilityId) && !isEmpty(shiftId),
    useErrorBoundary: false,
  });

  const { data, refetch, isSuccess } = radarLocationResponse;

  const isInsideFacilityGeofence = isAgentInsideGeofence(data?.user?.geofences, facilityId);

  const checkFacilityGeofence = async () => {
    const { data, isSuccess } = await refetch();
    const isInsideGeofence = isSuccess && isAgentInsideGeofence(data?.user?.geofences, facilityId);
    return {
      isInsideGeofence,
      isSuccess,
      radarLocation: data,
    };
  };

  return {
    radarLocationResponse,
    isInsideFacilityGeofence,
    checkFacilityGeofence,
    isSuccess,
    radarLocation: data,
  };
}

export { useRadarLocationApi };
