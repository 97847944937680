import { Capacitor } from "@capacitor/core";
import { isNullOrUndefined } from "@clipboard-health/util-ts";
import { locationService } from "@src/app/openShifts/urgentShifts/locationService";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { GeoLocation } from "@src/appV2/Location/index";
import { getUserCurrentLocation } from "@src/appV2/Location/providers/hypertrackLocationProvider";
import { type Shift } from "@src/lib/interface/src/lib/shift";
import { RadarTrackCallback } from "capacitor-radar";
import HyperTrack from "hypertrack-sdk-ionic-capacitor";
import { pick } from "lodash";

type Coordinates = [number, number] | undefined;

interface GeofenceStatusParams {
  shift: Shift;
  isHyperTrackEnabledForClockInOut: boolean;
  checkFacilityGeofence: () => Promise<{
    isInsideGeofence: boolean;
    isSuccess: boolean;
    radarLocation: RadarTrackCallback | undefined;
  }>;
}

interface GeofenceStatusResult {
  isLocationExperienceEnabled: boolean;
  isOutsideFacilityGeofence: boolean;
  location: Coordinates;
}

export async function getGeofenceStatus({
  shift,
  isHyperTrackEnabledForClockInOut,
  checkFacilityGeofence,
}: GeofenceStatusParams): Promise<GeofenceStatusResult> {
  let isLocationExperienceEnabled = false;
  let isOutsideFacilityGeofence = false;
  let location: Coordinates;

  if (shift.facility?.featureSettings?.radarTimekeepingValidations && shift.geofence) {
    if (isHyperTrackEnabledForClockInOut) {
      const orderHandle = `${shift._id}-${shift.agentId}`;

      const { isInsideGeofence, error } = await checkIsWorkerInsideFacilityGeofenceHyperTrack(
        orderHandle
      );

      if (error) {
        isLocationExperienceEnabled = false;
      } else {
        isOutsideFacilityGeofence = !isInsideGeofence;
        const { longitude, latitude } = await getUserCurrentLocation();
        location = longitude && latitude ? [longitude, latitude] : undefined;
        isLocationExperienceEnabled = true;

        logEvent(APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK, {
          metadata: {
            isHyperTrackEnabledForClockInOut,
            shift: pick(shift, ["shiftId", "agentId", "facilityId"]),
          },
        });
      }
    } else {
      const { isInsideGeofence, isSuccess, radarLocation } = await checkFacilityGeofence();
      isLocationExperienceEnabled = isSuccess;
      isOutsideFacilityGeofence = isSuccess && !isInsideGeofence;
      const { longitude, latitude } = radarLocation?.location || {};
      location = longitude && latitude ? [longitude, latitude] : undefined;
    }
  }

  return { isLocationExperienceEnabled, isOutsideFacilityGeofence, location };
}

export async function getDeviceLocationWithProvider(params: {
  isHyperTrackEnabledForClockInOut?: boolean;
}): Promise<{ location?: GeoLocation; error?: string }> {
  const { isHyperTrackEnabledForClockInOut } = params;

  try {
    if (isHyperTrackEnabledForClockInOut) {
      const { longitude, latitude } = await getUserCurrentLocation();

      if (isNullOrUndefined(longitude) || isNullOrUndefined(latitude)) {
        return { error: "Error getting location from Hypertrack" };
      }

      return {
        location: {
          longitude,
          latitude,
        },
      };
    }

    const { location } = await locationService.getLocation({
      desiredAccuracy: "high",
    });

    if (isNullOrUndefined(location)) {
      return { error: "Error getting location from Radar" };
    }

    return { location };
  } catch (error) {
    return {
      error: `Unhandled error getting location from ${
        isHyperTrackEnabledForClockInOut ? "Hypertrack" : "Radar"
      }`,
    };
  }
}

async function checkIsWorkerInsideFacilityGeofenceHyperTrack(
  orderHandle: string
): Promise<{ isInsideGeofence: boolean; error?: unknown }> {
  if (!Capacitor.isNativePlatform()) {
    const nonNativePlatformError = new Error(
      `Failed to fetch current order due to non-native platform.`
    );

    logError(
      APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED,
      {
        error: nonNativePlatformError,
        metadata: {
          orderHandle,
        },
      },
      true
    );

    return { isInsideGeofence: false, error: nonNativePlatformError };
  }

  const activeOrders = await HyperTrack.getOrders();
  const currentOrder = activeOrders.get(orderHandle);

  if (isNullOrUndefined(currentOrder)) {
    const activeOrderHandles = [...activeOrders.keys()];

    const failedToFetchCurrentOrderError = new Error(`Failed to fetch current order`);
    logError(
      APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED,
      {
        error: failedToFetchCurrentOrderError,
        metadata: {
          orderHandle,
          activeOrderHandles,
          numActiveOrders: activeOrderHandles.length,
        },
      },
      true
    );

    return { isInsideGeofence: false, error: failedToFetchCurrentOrderError };
  }

  switch (currentOrder.isInsideGeofence.type) {
    case "success": {
      logEvent(APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_SUCCEEDED, {
        metadata: {
          orderHandle,
          activeOrders,
          currentOrder,
        },
      });
      return { isInsideGeofence: currentOrder.isInsideGeofence.value };
    }
    case "failure": {
      const failedToFetchGeofenceStatusError = new Error(
        `Failed to fetch geofence status for current order`
      );
      logError(
        APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED,
        {
          error: failedToFetchGeofenceStatusError,
          metadata: {
            orderHandle,
            activeOrders,
            currentOrder,
          },
        },
        true
      );
      return { isInsideGeofence: false, error: failedToFetchGeofenceStatusError };
    }
  }
}
