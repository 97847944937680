import { IonButton, IonButtons, IonIcon } from "@ionic/react";
import { CustomBadge } from "@src/app/chat/customBadge";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { useAppSelector } from "@store/index";
import { chatbubblesOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";

interface HeaderMessagesIconProps {
  agentId?: string;
}

export function HeaderMessagesIcon(props: HeaderMessagesIconProps) {
  const { agentId } = props;

  const { channels } = useAppSelector((store) => store.chatStore);
  const history = useHistory();

  const unreadChannelCount = channels.reduce((memo, channel) => {
    const { unreadMessageCount } = channel;
    if (unreadMessageCount > 0) {
      return memo + unreadMessageCount;
    }
    return memo;
  }, 0);

  return (
    <>
      <IonButtons slot="end">
        <IonButton
          onClick={() => {
            logEvent(USER_EVENTS.MESSAGES_ICON_CLICKED, {
              agentId,
            });

            history.push(TabRouterPath.CHAT_CHANNELS_LIST);
          }}
        >
          <IonIcon slot="icon-only" icon={chatbubblesOutline} style={{ color: "white" }} />
        </IonButton>
        {unreadChannelCount > 0 && (
          <div style={CustomBadge}>{unreadChannelCount <= 99 ? unreadChannelCount : "99+"}</div>
        )}
      </IonButtons>
    </>
  );
}
