import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { Shift } from "@src/lib/interface";
/**
 * @deprecated Use the appV2 version
 * @file src/appV2/Facilities/useGeolocationTrackingForShiftsEnabled.ts
 */
const useGeolocationTrackingForShiftsEnabled = (shift?: Shift): boolean => {
  const geolocationTrackingForShiftsEnabledMap = useCbhFlag(
    CbhFeatureFlag.ENABLE_GEOLOCATION_TRACKING_FOR_SHIFTS,
    { defaultValue: {} }
  );

  const geolocationTrackingForShiftsEnabled = shift?.facility?.type
    ? geolocationTrackingForShiftsEnabledMap[shift.facility?.type] ?? true
    : true;

  return geolocationTrackingForShiftsEnabled;
};

export { useGeolocationTrackingForShiftsEnabled };
